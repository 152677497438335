import React, { useState, useEffect } from "react"
import SearchModule from "../search2/searchModule"

const CatalogListing = (props) => {
  const [searchFilters, setSearchFilters] = useState({})
  const [categoryId, setCategoryId] = useState(null)
  const [categoryBlurb, setCategoryBlurb] = useState(null)

  useEffect(() => {
    import(`../../../static/categories.json`).then(
      ({ default: categoriesMap }) => {
        if (props.rootCategory === "new-arrivals")
          setSearchFilters({ toggle: { isNewArrival: true } })
        else if (props.rootCategory === "featured") {
          console.log("featured")
          setSearchFilters({ toggle: { isFeatured: true } })
        }
        else {
          var indexPath = ""
          let pathSegments = props.location.pathname
            .replace("/catalog/", "")
            .split("/")
          var pathSegmentLookups = []
          var categoryId = null
          pathSegments.forEach((segment) => {
            indexPath = indexPath + `/${segment}`
            pathSegmentLookups.push(categoriesMap[indexPath].name)
            categoryId = categoriesMap[indexPath].sfId
            //categoryPath = categoryPath + `${categoriesMap[indexPath]} `
          })
          setCategoryId(categoryId)
          var categoryPath = pathSegmentLookups.join(" > ")
          const filter = {
            hierarchicalMenu: { "categories.lvl0": categoryPath },
            page: 1,
          }
          setSearchFilters(filter)
        }
      }
    )
  }, [props.location.pathname])

  useEffect(() => {
    if (categoryId)
      axios
        .get(
          `/categoryLookup?sfId=${categoryId}&version=${process.env.GATSBY_DBVERSION}`
        )
        .then((response) => {
          setCategoryBlurb(response.data)
        })
        .catch((error) => {
          console.error("cannot fetch catalog info")
        })
  }, [categoryId])

  return (
    <>
      <SearchModule showManufacturer={true} searchFilters={searchFilters} blurb={categoryBlurb} />
    </>
  )
}

export default CatalogListing
